export default [
  {
    name: "ligi",
    image: require("./ligi.jpg"),
    maskImage: require("./ligi-mask.jpg"),
    twitter: "https://twitter.com/mr_ligi",
    github: "https://github.com/ligi",
  },
  {
    name: "Afri",
    image: require("./afri.jpeg"),
    maskImage: require("./afri-mask.jpeg"),
    github: "https://github.com/q9f",
    website: "https://q9f.cc/",
  },
  {
    name: "Franziska",
    image: require("./franzi.jpeg"),
    maskImage: require("./franzi-mask.jpeg"),
    twitter: "https://twitter.com/_franzihei",
    github: "https://github.com/franzihei",
  },
  {
    name: "Jacob",
    maskImage: require("./jacob-mask.jpeg"),
    image: require("./jacob.jpeg"),
    twitter: "https://twitter.com/_czepluch",
    github: "https://github.com/czepluch",
  },
  {
    name: "Kaan",
    image: require("./Kaan.jpeg"),
    maskImage: require("./Kaan-mask.jpeg"),
    twitter: "https://twitter.com/kaanuzdogan",
    github: "https://github.com/kuzdogan",
  },
  {
    name: "Kirill",
    maskImage: require("./kirill-mask.jpeg"),
    image: require("./kirill.jpeg"),
    website: "https://pimenov.cc/",
  },
  {
    name: "Raul",
    image: require("./raul.jpeg"),
    maskImage: require("./raul-mask.jpeg"),
  },
  {
    name: "Stina",
    image: require("./stina.jpeg"),
    maskImage: require("./stina-mask.jpeg"),
    twitter: "https://twitter.com/stinalinneag",
  },
  {
    name: "Lea",
    image: require("./lea.jpeg"),
    maskImage: require("./lea-mask.jpeg"),
    twitter: "https://twitter.com/_schmitted",
  },
  {
    name: "Rose",
    image: require("./rose.gif"),
    maskImage: require("./rose-mask.gif"),
    twitter: "https://twitter.com/roseighteth",
  },
  {
    name: "Helena",
    image: require("./hels.jpeg"),
    maskImage: require("./hels-mask.jpeg"),
    twitter: "https://twitter.com/helsfoftroy",
  },
  {
    name: "Martin",
    image: require("./martin.jpeg"),
    maskImage: require("./martin-mask.jpeg"),
    twitter: "https://twitter.com/MartinBreiten",
  },
  {
    name: "MP",
    image: require("./MP.jpeg"),
    maskImage: require("./mp-mask.jpeg"),
    twitter: "https://twitter.com/MPtherealmvp",
  },
];

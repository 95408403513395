export default [
  {
    name: "depatchedmode",
    farcaster: "https://warpcast.com/depatchedmode",
    github: "https://github.com/depatchedmode",
    image: require("./depatchedmode.jpg"),
    // maskImage: require("./jacob-mask.jpg"),
  },
  {
    name: "Xavier",
    twitter: "https://twitter.com/XavierLava",
    github: "https://github.com/Xalava/",
    image: require("./xavier.jpg"),
    maskImage: require("./xavier-mask.jpeg"),
  },
  {
    name: "dcbuilder",
    twitter: "https://x.com/dcbuild3r",
    farcaster: "https://warpcast.com/dcbuilder",
    github: "https://github.com/dcbuild3r",
    image: require("./dcbuilder.jpeg"),
    maskImage: require("./dcbuilder-mask.jpg"),
  },
  {
    name: "Nick",
    twitter: "@DrNickA",
    gitlab: "https://gitlab.com/factorydao",
    image: require("./nick.jpg"),
    maskImage: require("./nick-mask.jpg"),
  },
  {
    name: "Boxer",
    twitter: "https://twitter.com/0xBoxer",
    github: "https://github.com/0xBoxer",
    image: require("./0xBoxer.jpg"),
    maskImage: require("./0xBoxer-mask.jpg"),
  },
  {
    name: "Ev_om",
    twitter: "https://twitter.com/0xEV_om",
    github: "https://github.com/0xEVom",
    image: require("./0xEV_om.jpg"),
    maskImage: require("./0xEV_om-mask.jpg"),
  },
  {
    name: "Deomaius",
    twitter: "https://twitter.com/deomaius",
    github: "https://github.com/deomaius",
    image: require("./deomaius.jpg"),
    maskImage: require("./deomaius-mask.jpg"),
  },
  {
    name: "Gajesh",
    twitter: "https://twitter.com/gajeshnaik",
    github: "https://github.com/Gajesh2007",
    image: require("./gajesh.jpg"),
    maskImage: require("./gajesh-mask.jpeg"),
  },
  {
    name: "Katya",
    twitter: "https://www.twitter.com/0xkatya",
    image: require("./0xkatya.jpg"),
    maskImage: require("./0xkatya-mask.jpg"),
  },
  {
    name: "Eugene",
    twitter: "https://twitter.com/bbeats1",
    github: "https://github.com/eleventh19",
    image: require("./bbeats.jpg"),
    maskImage: require("./bbeats-mask.jpg"),
  },
  {
    name: "Josh",
    farcaster: "https://warpcast.com/highonhopium",
    github: "https://github.com/hardyjosh",
    image: require("./josh2.jpeg"),
    maskImage: require("./josh2-mask.jpeg"),
  },
  {
    name: "Greg",
    twitter: "https://twitter.com/GregTheGreek",
    github: "https://github.com/GregTheGreek",
    image: require("./greg.jpg"),
    maskImage: require("./greg-mask.jpg"),
  },
  {
    name: "Ali Nuraldin",
    twitter: "https://twitter.com/Ali_run",
    github: "https://github.com/alirun",
    image: require("./alinuraldin.jpeg"),
    maskImage: require("./alinuraldin-mask.jpg"),
  },
  {
    name: "Damla Çağlayan",
    twitter: "https://twitter.com/damilicoo",
    github: "https://github.com/damilico",
    image: require("./damla.jpeg"),
    maskImage: require("./damla-mask.jpeg"),
  },
  {
    name: "Ira Nezhynska",
    image: require("./ira.jpg"),
    maskImage: require("./ira-mask.jpeg"),
  },
  {
    name: "George",
    twitter: "https://twitter.com/sick_nerdballer",
    github: "https://github.com/gkkobakhidze/",
    image: require("./george.jpg"),
    maskImage: require("./george-mask.jpeg"),
  },
  {
    name: "Praneeth",
    twitter: "https://twitter.com/bees_neeth",
    github: "https://github.com/mooniemama",
    image: require("./bees.jpg"),
    maskImage: require("./bees-mask.jpg"),
  },
  // {
  //   name: "Laura",
  //   twitter: "twitter.com/laurahelenwinn",
  //   github: "https://github.com/laurahelenwinn",
  //   image: require("./laura.jpg"),
  //   maskImage: require("./laura-mask.jpg"),
  // },
  {
    name: "Brett Sun",
    twitter: "https://twitter.com/sohkai",
    farcaster: "https://warpcast.com/sohkai",
    github: "https://github.com/sohkai",
    image: require("./brett.jpg"),
    maskImage: require("./brett-mask.jpeg"),
  },
  {
    name: "Mario H",
    twitter: "https://twitter.com/tmiychao",
    github: "https://github.com/taxmeifyoucan",
    image: require("./mario.jpg"),
    maskImage: require("./mario-mask.jpg"),
  },
];
